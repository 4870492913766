import * as React from 'react'
import { useBanquetProps } from 'banquet-runtime-modules'
import { Token } from '../Token'
import { PermissionsTester } from '../PermissionsTester'

export function UserAuth() {
  const { auth } = useBanquetProps()

  return (
    <div className='flex flex-col gap-4 p-2'>
      <Token />
      <div className='flex flex-col gap-2'>
        <h5 className='mx-auto font-semibold type-default'>User information</h5>
        {/* Could be changed to something more friendly */}
        <pre className='overflow-x-scroll rounded bg-gray-25 p-2'>
          <code className='type-subhead leading-tight'>
            {JSON.stringify(auth?.userInfo, null, 2)}
          </code>
        </pre>
      </div>
      <hr />
      <PermissionsTester />
    </div>
  )
}
