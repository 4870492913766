import * as React from 'react'
import { BanquetGraphiQL } from '@toasttab/banquet-graphql-playground'
/**
 * GraphqlPlayground component
 */
export const GraphqlPlayground = (props: any) => {
  const extraHeaders = React.useMemo(
    () => ({
      // legacy headers - only used for backwards compatibility
      'toast-user-guid': props?.auth?.userInfo?.guid || ''
    }),
    [props?.auth?.userInfo?.guid]
  )
  return <BanquetGraphiQL extraHeaders={extraHeaders} />
}
