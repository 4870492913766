import * as React from 'react'
import { AccordionItem } from '@toasttab/buffet-pui-accordion'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Button } from '@toasttab/buffet-pui-buttons'

interface WarningNoteProps {
  id: string
  title: string
  shouldBeShown?: boolean
  actionDescription?: string
  onActionClick?(): void
}
function WarningNote({
  id,
  title,
  actionDescription,
  children,
  onActionClick,
  shouldBeShown = false
}: React.PropsWithChildren<WarningNoteProps>) {
  if (!shouldBeShown) {
    return null
  }
  return (
    <AccordionItem id={id} title={title}>
      <div className='flex flex-col items-end gap-2'>
        <p>{children}</p>
        <Button onClick={onActionClick} variant='link'>
          {actionDescription}
        </Button>
      </div>
    </AccordionItem>
  )
}

interface WarningNotesProps {
  hasAnyDuplicates?: boolean
  hasAnyOverrideFailed?: boolean
  onSeeAllDuplicates?(): void
  onSeeAllFailedOverrides?(): void
}
export function WarningNotes({
  hasAnyDuplicates,
  hasAnyOverrideFailed,
  onSeeAllDuplicates,
  onSeeAllFailedOverrides
}: WarningNotesProps) {
  const shouldShowWarning = [hasAnyDuplicates, hasAnyOverrideFailed].some(
    (v) => v
  )

  if (!shouldShowWarning) {
    return null
  }

  return (
    <div className='grid grid-cols-1 gap-2'>
      <Alert variant='warning'>
        Some modules in the below list are in an unhealthy situation! Please
        read below notes if you own them.
      </Alert>
      <div>
        <WarningNote
          id='duplicate'
          title='Duplicate entries in the import maps'
          actionDescription='See all duplicate entries'
          shouldBeShown={hasAnyDuplicates}
          onActionClick={onSeeAllDuplicates}
        >
          Modules marked with the <Badge color='warning'>Duplicate</Badge> have
          been found in multiple import maps, which can happen if they are
          served by both SPA Control and Service Management. To solve this,
          please remove them from the legacy manifests, explained in the{' '}
          <a
            href='https://toasttab.atlassian.net/wiki/spaces/FE/pages/2905243694/How+to+migrate+a+SPA+to+SPA+control#Cleanup'
            rel='noopener noreferrer'
            className='inline-link'
          >
            How to migrate a SPA to SPA Control
          </a>{' '}
          document.
        </WarningNote>
        <WarningNote
          id='failed override'
          title='Some SPA overrides failed to load'
          actionDescription='See all SPAs with failed override'
          shouldBeShown={hasAnyOverrideFailed}
          onActionClick={onSeeAllFailedOverrides}
        >
          Modules marked with the <Badge color='warning'>Failed</Badge> have
          been found on the page, which can happen if they have an overridden
          URL that doesn't point to a valid resource. This is likely due to a
          SPA's `yarn start` command to have stopped or returned building
          issues.
        </WarningNote>
      </div>
    </div>
  )
}
