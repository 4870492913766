import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { resetDuplicateEntriesCache } from '@local/import-map-tool'
import { AppSetup } from './components/App'
import './index.css'

// Run on SPA mount to keep the cache active only within the page lifetime
resetDuplicateEntriesCache()

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-devt',
  singleSpaCssLifecycles,
  rootComponent: AppSetup
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
