import * as React from 'react'
import { useBanquetProps } from 'banquet-runtime-modules'
import { useClipboard } from 'use-clipboard-copy'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CopyIcon, CheckCircleSelectedIcon } from '@toasttab/buffet-pui-icons'

function useAuthToken() {
  const { auth } = useBanquetProps()
  const [token, setToken] = React.useState('Loading token...')

  React.useEffect(() => {
    auth
      ?.getTokenSilently()
      .then((token) => setToken(token))
      .catch((err) => console.error(err))
  }, [auth])

  return token
}

function ReactiveCopyIcon({ copied }: { copied: boolean }) {
  if (copied) {
    return <CheckCircleSelectedIcon className='text-success' title='Copied' />
  }
  return <CopyIcon title='Copy' />
}

export function Token() {
  const token = useAuthToken()
  const { copy, copied } = useClipboard({ copiedTimeout: 500 })

  return (
    <div className='grid grid-cols-1 gap-2'>
      <h5 className='text-center font-semibold type-default'>Access token</h5>
      <div className='flex flex-row gap-2'>
        <pre className='overflow-x-scroll rounded bg-gray-25 p-2'>
          <code className='select-all type-subhead leading-tight'>{token}</code>
        </pre>
        <IconButton
          onClick={() => copy(token)}
          icon={<ReactiveCopyIcon copied={copied} />}
        />
      </div>
    </div>
  )
}
