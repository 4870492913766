import * as React from 'react'
import { useMemo, useRef, useState } from 'react'
import { CellProps, Column, useTable } from 'react-table'
import cx from 'classnames'
import { Badge } from '@toasttab/buffet-pui-badge'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import {
  CancelIcon,
  ExpandIcon,
  SearchIcon,
  LookupChecksIcon
} from '@toasttab/buffet-pui-icons'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { SearchInput } from '@toasttab/buffet-pui-text-input'
import { Actions } from './Actions'
import { BadgeState } from './BadgeState'
import { ToastImport } from './importMapApi'
import { OverrideForm } from './OverrideForm'
import { RefreshButton } from './RefreshButton'
import { useImportMap } from './useImportMap'
import { filterModules, SupportedAttributes } from './utils'
import { WarningNotes } from './WarningNotes'

const COLUMNS: ReadonlyArray<Column<ToastImport>> = [
  {
    accessor: 'name',
    Header: 'SPA name',
    Cell(props: CellProps<ToastImport>) {
      const { isMountedApp, isDirty, isDuplicate, hasOverrideFailed } =
        props.row.original
      const name = props.value

      return (
        <div
          className={cx('flex flex-row flex-wrap items-center gap-2', {
            'font-semibold': isMountedApp
          })}
        >
          {name}
          {isDuplicate && <Badge color='warning'>Duplicate</Badge>}
          {hasOverrideFailed && <Badge color='warning'>Failed</Badge>}
          {isDirty && <RefreshButton />}
        </div>
      )
    }
  },
  {
    accessor: 'state',
    Header() {
      return (
        <div className='flex flex-row justify-center'>
          <p>State</p>
        </div>
      )
    },
    Cell({ value }) {
      return (
        <div className='flex flex-row justify-center'>
          <BadgeState state={value} />
        </div>
      )
    }
  },
  {
    id: 'actions',
    Header() {
      return (
        <div className='flex flex-row justify-center'>
          <p>Actions</p>
        </div>
      )
    },
    Cell(props: CellProps<ToastImport>) {
      const { name, isOpen } = props.row.original
      // @ts-ignore
      const { toggleOpen } = props
      return (
        <div className='flex flex-row justify-center'>
          <IconButton
            icon={<ExpandIcon isExpanded={isOpen} />}
            cropToIcon
            onClick={() => {
              toggleOpen(name)
            }}
          />
        </div>
      )
    }
  }
]

const searchPlaceholder = `Search by name or filter by supported attributes: "${SupportedAttributes.DUPLICATE}", "${SupportedAttributes.FAILED_OVERRIDE}"`

export function ImportMapTool() {
  const { data, mutations } = useImportMap()
  const searchRef = useRef<HTMLInputElement>(null)
  const [query, setQuery] = useState('')

  const modules = useMemo(() => filterModules(data ?? [], query), [query, data])
  const hasAnyDuplicates = data?.some(
    (module) => module.isDuplicate && module.state !== 'devliboverride'
  )
  const isAnyModuleDirty = useMemo(
    () => data?.some((module) => module.isDirty) ?? false,
    [data]
  )
  const hasAnyOverrideFailed = data?.some((module) => module.hasOverrideFailed)

  const { headerGroups, rows, getTableBodyProps, prepareRow } = useTable({
    columns: COLUMNS,
    data: modules || [] // No loading state for now, it's pretty fast
  })

  const clearSearchHandler = () => {
    setQuery('')
    searchRef.current?.focus()
  }

  return (
    <div className='grid grid-cols-1 gap-2'>
      <SearchInput
        autoComplete='off'
        ref={searchRef}
        icon={<SearchIcon />}
        name='spa-search'
        onChange={({ target }) => setQuery(target.value)}
        placeholder={searchPlaceholder}
        value={query}
        suffix={
          query && (
            <IconButton
              icon={<CancelIcon />}
              textClassName='text-gray-50 hover:text-gray-75 focus:text-gray-75'
              contained
              onClick={clearSearchHandler}
            />
          )
        }
      />
      <Actions
        isAnyModuleDirty={isAnyModuleDirty}
        addOverride={mutations.addOverride}
        resetOverrides={mutations.resetOverrides}
      />
      <WarningNotes
        hasAnyDuplicates={hasAnyDuplicates}
        hasAnyOverrideFailed={hasAnyOverrideFailed}
        onSeeAllDuplicates={() => setQuery(SupportedAttributes.DUPLICATE)}
        onSeeAllFailedOverrides={() =>
          setQuery(SupportedAttributes.FAILED_OVERRIDE)
        }
      />
      {query && modules.length === 0 ? (
        <EmptyState
          className='py-10'
          button={<Button onClick={clearSearchHandler}>Clear search</Button>}
          icon={<LookupChecksIcon />}
          title='No results found'
        >
          No results were found for <b>{query}</b>.
        </EmptyState>
      ) : (
        <Table variant='plain'>
          <Head>
            {headerGroups.map((group) => (
              <Row {...group.getHeaderGroupProps()}>
                {group.headers.map((column) => (
                  <HeadingCell {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </HeadingCell>
                ))}
              </Row>
            ))}
          </Head>
          <Body {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              const { isOpen } = row.original
              const { key, ...rowProps } = row.getRowProps()
              return (
                <React.Fragment key={key}>
                  <Row
                    {...rowProps}
                    className={cx('border-b-0 last:border-b', {
                      'bg-darken-4': i % 2 !== 0
                    })}
                  >
                    {row.cells.map((cell) => {
                      const cellProps = cell.getCellProps()
                      return (
                        <Cell {...cellProps}>
                          {cell.render('Cell', { ...mutations })}
                        </Cell>
                      )
                    })}
                  </Row>
                  {isOpen && (
                    <Row
                      {...rowProps}
                      className={cx({
                        'bg-darken-4': i % 2 !== 0
                      })}
                    >
                      <Cell colSpan={3} className='py-4 pt-0'>
                        <OverrideForm {...row.original} {...mutations} />
                      </Cell>
                    </Row>
                  )}
                </React.Fragment>
              )
            })}
          </Body>
        </Table>
      )}
    </div>
  )
}
