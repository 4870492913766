import * as React from 'react'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { PreferencesProvider } from '@local/preferences-tool'
import { TadpoleApolloProvider } from '@local/tadpole-node-client'
import { PortalsParent } from '../PortalsParent'
import { App } from './App'

export function AppSetup() {
  return (
    <PreferencesProvider>
      <TadpoleApolloProvider>
        <SnackBarProvider domRoot='devt-portal-container'>
          <App />
        </SnackBarProvider>
        <PortalsParent />
      </TadpoleApolloProvider>
    </PreferencesProvider>
  )
}
