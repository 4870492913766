import * as React from 'react'
import { Badge, BadgeProps } from '@toasttab/buffet-pui-badge'
import { State } from './importMapApi'

interface BadgeStateProps extends Partial<BadgeProps> {
  state: State
}

function getBadgeColor(state: State): BadgeProps['color'] {
  if (state === 'devliboverride') {
    return 'warning'
  }
  if (state === 'disabled') {
    return 'gray'
  }
  if (state === 'overridden') {
    return 'brand'
  }
  return 'neutral'
}

export function BadgeState({ state, ...props }: BadgeStateProps) {
  return (
    <Badge {...props} color={getBadgeColor(state)}>
      {state === 'devliboverride' ? 'Dev Lib' : state}
    </Badge>
  )
}
