import { ToastImport } from './importMapApi'

export enum SupportedAttributes {
  DUPLICATE = 'duplicate',
  FAILED_OVERRIDE = 'failed'
}

function termsToFilteringAttributes(terms: string[]) {
  return function (module: ToastImport) {
    return terms.every((term) => {
      if (term === SupportedAttributes.DUPLICATE) {
        return module.isDuplicate
      }
      if (term === SupportedAttributes.FAILED_OVERRIDE) {
        return module.hasOverrideFailed
      }
      return module.name.includes(term)
    })
  }
}

export function filterModules(modules: ToastImport[], searchTerms: string) {
  const terms = searchTerms.toLocaleLowerCase().split(' ')
  const byAttribute = termsToFilteringAttributes(terms)
  return modules.filter(byAttribute)
}
