import * as React from 'react'
import cx from 'classnames'
import { useBanquetProps } from 'banquet-runtime-modules'
import { useIsModalWidthNarrow } from '@local/preferences-tool/hooks/useIsModalNarrow'
import {
  AdministratorPermissions,
  CustomerPermissions,
  checkAdministratorPermissions,
  checkCustomerPermissions
} from '@toasttab/check-permissions'
import { PermissionTester } from './PermissionTester'
import {
  AdministratorPermissionsOptions,
  CustomerPermissionsOptions
} from './permissionsOptions'

export function PermissionsTester() {
  const { auth } = useBanquetProps()
  const isModalNarrow = useIsModalWidthNarrow()

  return (
    <div className='flex flex-col gap-2'>
      <h5 className='text-center font-semibold type-default'>
        Permissions tester
      </h5>
      <div
        className={cx('grid grid-cols-1 gap-4', {
          'md:grid-cols-2': !isModalNarrow
        })}
      >
        <PermissionTester<CustomerPermissions>
          label='Customer permission'
          options={CustomerPermissionsOptions}
          check={(bit) => {
            if (!auth?.userInfo.customerPermissions) {
              return false
            }
            return checkCustomerPermissions(
              auth?.userInfo.customerPermissions,
              {
                permissionBits: bit
              }
            )
          }}
        />
        <PermissionTester<AdministratorPermissions>
          label='Administrator permission'
          options={AdministratorPermissionsOptions}
          check={(bit) => {
            if (!auth?.userInfo.administratorPermissions) {
              return false
            }
            return checkAdministratorPermissions(
              auth?.userInfo.administratorPermissions,
              {
                permissionBits: bit
              }
            )
          }}
        />
      </div>
    </div>
  )
}
