import * as React from 'react'
import { useLocalStorage } from 'react-use'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { clearHeapCookies } from '../utils'

export interface ApplicationToolsProps {
  testId?: string | number
}

export const HEAP_ENABLED_KEY = 'toast-dev-heap'

/**
 * ApplicationTools component
 * The tools for the application
 */
export const ApplicationTools = ({
  testId = 'ApplicationTools'
}: ApplicationToolsProps) => {
  const [isHeapEnabled, setIsHeapEnabled, unsetIsHeapEnabled] =
    useLocalStorage(HEAP_ENABLED_KEY)
  const [isShowingRefreshAlert, setIsShowingRefreshAlert] =
    React.useState(false)

  const toggleDevHeap = () => {
    if (isHeapEnabled) {
      unsetIsHeapEnabled()
    } else {
      setIsHeapEnabled('true')
    }
    setIsShowingRefreshAlert(true)
  }

  const closeHeapAlert = () => setIsShowingRefreshAlert(false)

  return (
    <article data-testid={testId} className='space-y-4'>
      <header>
        <h3 className='font-semibold type-default'>Heap analytics</h3>
        <p className='type-caption'>
          Heap is disabled by default for non-production environments. Enable it
          for testing but be careful - it might create lots of cookies.
        </p>
      </header>
      {isShowingRefreshAlert && (
        <Alert onDismiss={closeHeapAlert} className='w-full'>
          Refresh your browser for the change to take effect.
        </Alert>
      )}
      <ButtonGroup>
        <Button onClick={toggleDevHeap}>
          {isHeapEnabled ? 'Disable' : 'Enable'} Heap
        </Button>
        <Button
          onClick={() => {
            clearHeapCookies()
            alert('Cookies cleared!')
          }}
        >
          Clear cookies
        </Button>
      </ButtonGroup>
    </article>
  )
}
