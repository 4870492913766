import React from 'react'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'

export const RefreshButton = () => (
  <IconButton
    cropToIcon
    icon={<AutorenewIcon />}
    onClick={() => window.location.reload()}
  />
)
